<template>
  <div>
    <Toast position="top-right" />
    <div class="p-grid crud-demo">
      <div class="p-col-12">
        <div class="card">
          <br />
          <DataTable
            dataKey="id"
            :value="listData"
            :paginator="true"
            :rows="10"
            class="p-datatable-customers"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="[5, 10, 25]"
            currentPageReportTemplate="{totalRecords} paiements"
            responsiveLayout="scroll"
          >
            <template #header>
              <div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between p-ai-start p-ai-md-center">
                <h5 class="p-mb-2 p-m-md-0">Gestion des paiements</h5>

                <span v-bind:style="{ width: '23%' }" class=""> </span>
              </div>
              <br />

              <div class="filters">
                <div class="filter__item">
                  <label class="filter__label" for="">Statut</label>
                  <Dropdown
                    id="state"
                    v-model="filterValues.status"
                    :options="dropdownValues"
                    optionLabel="name"
                  ></Dropdown>
                </div>
                <div class="filter__item">
                  <label class="filter__label" for="">Entreprise</label>
                  <InputText v-model="filterValues.entrepriseName" placeholder="Rechercher une entreprise" />
                  <!--<Button type="button" @click="searching" icon="pi pi-search" class="p-d-none p-d-md-inline-flex" />-->
                </div>
                <div class="filter__item">
                  <label class="filter__label" for="">Type de paiements</label>
                  <Dropdown
                    id="state"
                    v-model="filterValues.paymentType"
                    :options="filterOptions.paymentTypes"
                    optionLabel="name"
                  ></Dropdown>
                </div>
                <div class="filter__item">
                  <label class="filter__label" for="profile-entreprise-id">Profil d'entreprise</label>
                  <Dropdown
                    id="profile-entreprise-id"
                    v-model="filterValues.companyProfile"
                    :options="filterOptions.companyProfiles"
                    optionLabel="name"
                  ></Dropdown>
                </div>
                <!--<div class="filter__item">
                  <label class="filter__label" for="">Montant</label>
                  <Dropdown id="state" optionLabel="name"></Dropdown>
                </div>-->
                <div class="filter__item">
                  <label class="filter__label" for="">Email</label>
                  <InputText type="text" v-model="filterValues.email" />
                </div>
                <div class="filter__item">
                  <label class="filter__label" for="">Intervalle de dates</label>
                  <Calendar
                    :showIcon="true"
                    selectionMode="range"
                    dateFormat="dd/mm/yy"
                    v-model="filterValues.dateRange"
                  />
                </div>

                <div class="filter--ml">
                  <div class="filter__item">
                    <label class="filter__label" for=""></label>
                    <Button v-tooltip.top="'Exporter'" icon="pi pi-download" @click="exportData($event)" />
                  </div>
                  <div class="filter__item">
                    <label class="filter__label" for=""></label>
                    <Button
                      class="p-button-help"
                      v-tooltip.top="'Effacer les filtres'"
                      icon="pi pi-filter-slash"
                      @click="resetFilter"
                    />
                  </div>
                </div>
              </div>
            </template>
            <Column field="statut" header="Statut" :sortable="true">
              <!--template
                >&nbsp;&nbsp;
                paymentStatusMapping
                <span v-if="false" :class="'product-badge status-outofstock'">{{
                  dropdownValue.name == 'Tous' ? 'En attente' : 'En attente'
                }}</span>
                <span v-show="false" :class="'product-badge status-lowstock'">{{
                  dropdownValue.name == 'Tous' ? 'En cours' : 'En cours'
                }}</span>
                <span v-show="false" :class="'product-badge status-outofstock'">{{
                  dropdownValue.name == 'Tous' ? 'Rejeté' : 'Rejeté'
                }}</span>
                <span v-show="false" :class="'product-badge status-instock'">{{
                  dropdownValue.name == 'Tous' ? 'Validé' : 'Validé'
                }}</span>
              </template>-->
              <template #body="slotProps"
                >&nbsp;&nbsp;
                <span :class="'product-badge ' + getStatusClassName(slotProps.data)">{{
                  paymentStatusMapping[slotProps.data.statut]
                }}</span>
              </template>
            </Column>
            <Column field="details.name" header="Nom de l'entreprise" :sortable="true" bodyStyle="text-indent:1em">
              <template #body="slotProps">{{ slotProps.data.details?.name }} </template>
            </Column>
            <Column field="details.email" header="Adresse e-mail" :sortable="true" bodyStyle="text-indent:1em">
              <template #body="slotProps">{{ slotProps.data.details.email }}</template>
            </Column>
            <Column
              field="details.montantSouscriptionEnCours"
              header="Montant du paiement"
              :sortable="true"
              bodyStyle="text-indent:1em"
            >
              <!-- {{ slotProps.data.amount?.toLocaleString() || 'N/A' }} -->
              <template #body="slotProps">
                {{
                  tarifications
                    ?.filter((e) => e.rang === slotProps.data?.details.rangSouscriptionEnCours)[0]
                    ?.montant_xof.toLocaleString()
                }}
                CFA
              </template>
            </Column>
            <Column field="typePaiement" header="Type de paiement" :sortable="true" bodyStyle="text-indent:1em">
              <template #body="slotProps">{{ enumMap[slotProps.data.typePaiement] }} </template>
            </Column>

            <Column
              field="justificatif"
              header="Justificatif de paiement"
              :sortable="false"
              bodyStyle="text-indent:1em"
            >
              <template #body="slotProps">
                <span class="p-column-title">Visualiser</span>
                <preview-file-btn
                  :title="'Jutificatif de paiement'"
                  :url="slotProps.data.justificatif"
                  :btnLabel="'Visualiser'"
                  @triggered="handleShowPreview"
                />
              </template>
            </Column>
            <Column field="datePaiement" header="Date de paiement" :sortable="true" bodyStyle="text-indent:1em">
              <template #body="slotProps">
                {{ getFormattedDate(slotProps.data.datePaiement) }}
              </template>
            </Column>

            <Column field="action" header="Actions" :sortable="false" style="width: 11em" bodyStyle="text-indent:1em">
              <template #body="slotProps">
                <Button
                  icon="pi pi-eye"
                  v-tooltip.top="'Voir les détails'"
                  class="p-button-outlined p-button-rounded p-button-submit"
                  @click="onToggleDetails(slotProps.data)"
                />
                &nbsp;
                <Button
                  v-show="isPaymentSubmitted(slotProps.data)"
                  class="p-button-outlined p-button-rounded p-button-success"
                  v-tooltip.top="'Valider'"
                  @click="handleOnValidate(slotProps.data)"
                  icon="pi pi-thumbs-up"
                />

                &nbsp;
                <Button
                  v-show="isPaymentSubmitted(slotProps.data)"
                  icon="pi pi-thumbs-down"
                  v-tooltip.top="'Rejeter'"
                  class="p-button-outlined p-button-rounded p-button-warning"
                  @click="handleOnReject(slotProps.data)"
                />
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
    </div>
    <FileViewer
      v-if="null !== fileViewer.url"
      :url-link="fileViewer.url"
      :original-url="fileViewer.originalUrl"
      :title="fileViewer.title"
      v-on:close="handleOnClose"
    />
    <Dialog
      v-model:visible="dialogStates.ValidationView"
      :style="{ width: '600px' }"
      header="Êtes-vous sûr de vouloir valider le paiement ?"
      :modal="true"
      class="p-fluid"
    >
      <template #footer>
        <Button label="NON" class="p-button p-component p-button-outlined p-button-danger" @click="handleHideDialogs" />
        <Button
          label="OUI"
          class="p-button p-component p-button-outlined p-button-success"
          @click="handleOnConfirmValidation"
        />
      </template>
    </Dialog>

    <Sidebar class="p-sidebar-md" v-model:visible="dialogStates.DetailsView" position="right">
      <h4>Détails du paiement de l'entreprise {{ selectedSubscription.details.name }}</h4>
      <br />
      <company-detail-list :subscription="selectedSubscription" />
      <br />
      <Button
        v-show="isPaymentSubmitted(selectedSubscription)"
        icon="pi pi-thumbs-down"
        label="Rejeter"
        v-tooltip.top="'Rejeter'"
        class="p-button-outlined p-button-rounded p-button-warning"
        @click="handleOnReject(selectedSubscription)"
      />
      &nbsp;&nbsp;
      <Button
        v-show="isPaymentSubmitted(selectedSubscription)"
        icon="pi pi-check"
        label="Valider"
        class="p-button-outlined p-button-rounded p-button-success"
        @click="handleOnValidate(selectedSubscription)"
      />
    </Sidebar>

    <Dialog
      v-model:visible="dialogStates.RejectionView"
      :style="{ width: '450px' }"
      header="Motif du rejet du paiement"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="description">Objet(s) du rejet</label>
        <Textarea
          :class="{ 'p-invalid': !payload.message && submitted }"
          id="price"
          v-model="payload.message"
          required="true"
          rows="10"
          cols="25"
        />
        <small style="color: red" v-show="!payload.message && submitted">
          Veuillez renseigner le motif du rejet.
        </small>
      </div>
      <template #footer>
        <Button
          label="Annuler"
          icon="pi pi-times"
          class="p-button p-component p-button-outlined p-button-danger"
          @click="handleHideDialogs"
        />
        <Button
          :loading="loading"
          label="Rejeter"
          icon="pi pi-check"
          class="p-button p-component p-button-outlined p-button-success"
          @click="handleOnConfirmRejection"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import FileViewer from '@/components/FileViewer';
import PreviewFileBtn from '@/components/preview-file-btn';
import CompanyDetailList from '@/components/company-detail-list';
import EnumFormatMixin from '@/mixins/enum-format';
import DateFormatMixin from '@/mixins/date-format';
import LoggedUserMixin from '@/mixins/logged-user';
import { PaymentStatusAliasMixin } from '@/mixins/validation-status';
import DocumentManagerMixin from '@/mixins/document-manager';
import { BackendUrls } from '@/services/urlsConfigs';
import { useVuelidate } from '@vuelidate/core';
import http from '@/services/http.service';
import moment from 'moment';
import XLSX from 'xlsx';
import Http from '../services/http-v2.service';

export default {
  mixins: [EnumFormatMixin, DocumentManagerMixin, LoggedUserMixin, DateFormatMixin, PaymentStatusAliasMixin],
  components: {
    FileViewer,
    CompanyDetailList,
    PreviewFileBtn,
  },
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      tarifications: [],
      dialogStates: {
        DetailsView: false,
        ValidationView: false,
        RejectionView: false,
      },
      tarifsAsArray: [],
      tarifs: [],
      listData: [],
      response: {
        data: [],
        meta: {},
      },
      selectedSubscription: null,
      dropdownValues: [
        { name: 'En cours', id: 'POSTE,TRIAL_PAYMENT_PENDING' },
        { name: 'Validé', id: 'PAIEMENT_APPROUVE' },
        { name: 'Rejeté', id: 'PAIEMENT_REJETE' },
        { name: 'Période de grâce', id: 'TRIAL' },
        { name: 'Tous', id: 'PAIEMENT_APPROUVE,POSTE,PAIEMENT_REJETE,TRIAL_PAYMENT_PENDING,TRIAL' },
      ],
      filterValues: {
        status: { name: 'En cours', id: 'POSTE' },
        entrepriseName: null,
        paymentType: null,
        companyProfile: null,
        email: null,
        dateRange: null,
      },
      filterOptions: {
        paymentTypes: [
          { name: 'Carte bancaire', id: 'CARTE_BANCAIRE' },
          { name: 'Virement', id: 'VIREMENT' },
          { name: 'Chèque', id: 'CHEQUE' },
          { name: 'Vide', id: null },
        ],
        companyProfiles: [],
      },
      submitted: false,
      payload: { message: null },
    };
  },
  mounted() {
    if (localStorage.getItem('token')) {
      let exelScript = document.createElement('script');
      exelScript.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/xlsx/0.13.1/xlsx.full.min.js');
      document.head.appendChild(exelScript);

      this.fetchDomainesMetiers();
      this.fetchTarifs();
      this.fetchSubscriptions('status=POSTE,TRIAL_PAYMENT_PENDING&sort=datePaiement,asc');
      this.getTarifications();
    } else {
      this.$router.push('/login');
    }
  },
  watch: {
    filterValues: {
      handler: function (value) {
        this.refreshPage(value);
      },
      deep: true,
    },
  },
  methods: {
    async getTarifications() {
      await http.get('/tarifs?limit=100?sort=montant_xof,asc', this.config).then((res) => {
        this.tarifications = res.data;
        this.tarifications.sort((a, b) => b.montant_xof - a.montant_xof);
      });
    },

    getStatusClassName(subscription) {
      switch (subscription.statut) {
        case 'POSTE':
          return 'status-lowstock';
        case 'PAIEMENT_APPROUVE':
          return 'status-instock';
        case 'TRIAL':
          return 'status-renewal';
        case 'ECHU':
          return 'status-outofstock';
        case 'PAIEMENT_REJETE':
          return 'status-outofstock';
        case 'TRIAL_PAYMENT_PENDING':
          return 'status-lowstock';
        default:
          return '';
      }
    },
    fetchTarifs() {
      http({
        method: 'get',
        url: '/tarifs',
      }).then((response) => {
        this.filterOptions.companyProfiles = response.data;
        this.filterOptions.companyProfiles.push({ id: null, adherant: 'Aucun', rang: null });
        this.tarifs = this.filterOptions.companyProfiles.reduce((h, tarif) => ({ ...h, [tarif.rang]: tarif }), {});
      });
    },

    getParamsUrl(value) {
      const keyMap = {};
      if (this.filterValues.status.id) keyMap.status = value.status.id;
      if (this.filterValues.entrepriseName) keyMap.enterpriseName = value.entrepriseName;
      if (this.filterValues.email) keyMap.enterpriseEmail = value.email;
      if (this.filterValues.paymentType) keyMap.paymentType = value.paymentType;
      if (this.filterValues.companyProfile?.rang) keyMap.rank = value.companyProfile.rang;
      const dateRange = this.filterValues.dateRange;
      if (dateRange && dateRange.length > 1) {
        keyMap.startAt = moment(dateRange[0]).format('YYYY-MM-DD');
        keyMap.endAt = moment(dateRange[1]).format('YYYY-MM-DD');
      }
      const params =
        Object.keys(keyMap).reduce((url, key) => `${url}${key}=${keyMap[key]}&`, '') + 'sort=datePaiement,asc';
      return params;
    },

    onToggleDetails(subscription) {
      this.selectedSubscription = subscription;
      this.dialogStates.DetailsView = !this.dialogStates.DetailsView;
    },

    refreshPage(value) {
      const params = this.getParamsUrl(value);
      this.fetchSubscriptions(params);
    },

    exportData(event) {
      event.preventDefault();
      let filename = `${moment(new Date()).format('DDMMYYYYHHmmss')}-payments-reports.xlsx`;
      let data = [];
      http.get(BackendUrls.GetListPayments()).then((response) => {
        data = data.concat(
          response.data
            .filter((row) => row.datePaiement)
            .map((row) => {
              const entreprise = row.enterprise;
              return {
                STATUS: row.statut,
                ENTREPRISE: entreprise.name,
                POINT_FOCAL: entreprise.users
                  .map((u) => `${u.firstName} ${u.lastName},${u.mobile},${u.phone}`)
                  .join('|'),
                EMAIL: entreprise.email,
                ENTREPRISE_TEL_1: entreprise.mobile,
                ENTREPRISE_TEL_2: entreprise.phone,
                ADRESSE: entreprise.address,
                SITE_WEB: entreprise.www,
                PAYS: entreprise.name,
                MONTANT: this.filterOptions.companyProfiles.find((p) => p.rang === entreprise.rank)?.montant_xof,
                TYPE_PAIEMENT: row?.typePaiement,
                DATE_PAIEMENT: moment(row?.datePaiement).format('DD/MM/yyyy'),
                PROFIL: this.filterOptions.companyProfiles.find((p) => p.rang === entreprise.rank)?.adherant,
                FORM_JURIDIQUE: entreprise.legalForm?.libelle,
                ID_FISCAL: entreprise.taxIdNumber,
                RC: entreprise.tradeRegister,
                DATE_CREATION: moment(entreprise.companyCreatedAt).format('DD/MM/yyyy'),
              };
            })
        );
        var ws = XLSX.utils.json_to_sheet(data);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Payments');
        XLSX.writeFile(wb, filename);
      });
    },

    fetchSubscriptions(parameters) {
      return Http.get(BackendUrls.GetListSubscriptions(parameters))
        .then(this.handleFetchSubscriptionSucceed)
        .catch(this.handleFetchSubscriptionFailed);
    },

    handleFetchSubscriptionSucceed(response) {
      this.listData = response.data.data;
      this.response.data = response.data.data;
      this.response.meta = response.data.meta;
      return response;
    },

    handleFetchSubscriptionFailed() {},

    fetchDomainesMetiers() {
      return http({
        method: 'get',
        url: '/domaines-metiers?limit=5000',
      }).then((response) => {
        this.domainesMetiers = response.data.data;
      });
    },

    handleOnReject(subscription) {
      this.selectedSubscription = subscription;
      this.dialogStates.RejectionView = !this.dialogStates.RejectionView;
    },

    handleOnValidate(subscription) {
      this.selectedSubscription = subscription;
      this.dialogStates.ValidationView = !this.dialogStates.ValidationView;
    },

    handleOnConfirmRejection(event) {
      this.submitted = true;
      const subscription = this.selectedSubscription;
      console.log({ subscription, event });
      Http.post(BackendUrls.PostRejectPayment(subscription.id), {
        message: this.payload.message,
      })
        .then(this.handleRejectionSucceed)
        .catch(this.handleRejectionFailed);
    },

    handleRejectionSucceed() {
      //TODO VUEX.
      this.refreshPage(this.filterValues);
      this.handleHideDialogs();
      this.$toast.add({
        severity: 'success',
        summary: 'Succès',
        detail: "L'abonnement a bien été rejeté.",
        life: 3000,
      });
    },

    handleRejectionFailed() {
      this.$toast.add({
        severity: 'error',
        summary: 'Erreur',
        detail: "Echec du rejet de l'abonnement.",
        life: 3000,
      });
    },

    handleOnConfirmValidation() {
      const subscription = this.selectedSubscription;
      Http.post(BackendUrls.PostValidatePayment(subscription.id), {
        subscriptionState: 'NONE',
      })
        .then(this.handleValidationSucceed)
        .catch(this.handleValidationFailed);
    },

    handleValidationSucceed() {
      this.refreshPage(this.filterValues);
      this.handleHideDialogs();
      this.$toast.add({
        severity: 'success',
        summary: 'Succès',
        detail: 'Le paiement a bien été validé.',
        life: 3000,
      });
    },

    handleValidationFailed() {
      this.$toast.add({
        severity: 'error',
        summary: 'Erreur',
        detail: 'Echec de la validation du paiement.',
        life: 3000,
      });
    },

    resetFilter() {
      this.filterValues.companyProfile = null;
      this.filterValues.entrepriseName = null;
      this.filterValues.paymentType = null;
      this.filterValues.email = null;
      (this.filterValues.dateRange = null), (this.filterValues.status = { name: 'En cours', id: 1 });
    },

    handleHideDialogs() {
      this.dialogStates.RejectionView = false;
      this.dialogStates.DetailsView = false;
      this.dialogStates.ValidationView = false;
    },
  },
};
</script>

<style scoped lang="scss">
.filters {
  display: flex;
}
.filter__item {
  display: inline-grid;
  margin-right: 5px;
}
.filter__label {
  margin: 6px 1px;
  font-weight: 500;
}

.filter--ml {
  margin-left: auto;
}

.product-image {
  width: 100px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
  width: 150px;
  margin: 0 auto 2rem auto;
  display: block;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;

  &.status-instock {
    background: #c8e6c9;
    color: #256029;
  }

  &.status-outofstock {
    background: #ffcdd2;
    color: #c63737;
  }

  &.status-lowstock {
    background: #feedaf;
    color: #8a5340;
  }

  &.status-init {
    background: #deddf5;
    color: #101b61;
  }

  &.status-renewal {
    background: #eccfff;
    color: #694382;
  }
}
</style>
