import axios from 'axios';

const DocumentManagerMixin = {
  data: () => ({
    fileViewer: {
      url: null,
      originalUrl: null,
      title: null,
    },
  }),
  methods: {
    handleOnClose: function () {
      this.fileViewer.url = null;
      this.fileViewer.title = null;
      this.fileViewer.originalUrl = null;
    },
    handleShowPreview: function (event) {
      let { url, title, blobType } = event;
      const isLocalUrl = url.includes('localhost') || /^blob/.test(url);
      url = isLocalUrl ? url : 'https' + url.substr(4);

      axios
        .get(url, { responseType: 'blob' })
        .then((response) => {
          const blob = new Blob([response.data], { type: blobType });
          this.fileViewer.url = URL.createObjectURL(blob);
          this.fileViewer.originalUrl = url;
          this.fileViewer.title = title;
        })
        .catch(console.error);
    },
  },
};

export default DocumentManagerMixin;
