<template>
  <Button v-show="true" :loading="isLoading" :label="btnLabel" class="p-mr-2 p-mb-2" @click="handleOnClick" />
  <span v-show="!url && false">N/A</span>
</template>

<script>
export default {
  name: 'PreviewFileBtn',
  props: ['btnLabel', 'isLoading', 'url', 'title'],
  methods: {
    handleOnClick() {
      const extension = this.url.substr(-4);
      const blobType = extension === '.pdf' ? 'application/pdf' : 'image/png';
      this.$emit('triggered', { url: this.url, title: this.title, blobType });
    },
  },
};
</script>

<style>
.max-w-20 {
  max-width: 20em;
}
</style>
