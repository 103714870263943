const NONE = 'NONE';
const CREATED = 'CREATED';
const INITIE = 'INITIE';
const ENTERPRISE_INITIATED = 'ENTERPRISE_INITIATED';
const EN_COURS = 'EN_COURS';
const POSTE = 'POSTE';
const REJETE = 'REJETE';
const APPROUVE = 'APPROUVE';
const PAIEMENT_REJETE = 'PAIEMENT_REJETE';
const PAIEMENT_APPROUVE = 'PAIEMENT_APPROUVE';
const TRIAL = 'TRIAL';
const TRIAL_PAYMENT_PENDING = 'TRIAL_PAYMENT_PENDING';
const ECHU = 'ECHU';

export const SubscriptionStatus = {
  NONE,
  CREATED,
  INITIE,
  ENTERPRISE_INITIATED,
  EN_COURS,
  REJETE,
  APPROUVE,
  POSTE,
  PAIEMENT_REJETE,
  PAIEMENT_APPROUVE,
  TRIAL,
  TRIAL_PAYMENT_PENDING,
  ECHU,
};

export const SubscriptionStatusAliasMixin = {
  SUBMISSION_PENDING: 'CREATED,INITIE,ENTERPRISE_INITIATED',
  PENDING: EN_COURS,
  REJECTED: REJETE,
  VALIDATED: 'APPROUVE,POSTE,PAIEMENT_REJETE,PAIEMENT_APPROUVE,TRIAL',
  ALL: 'CREATED,INITIE,ENTERPRISE_INITIATED,EN_COURS,REJETE,APPROUVE,POSTE,PAIEMENT_REJETE,PAIEMENT_APPROUVE,TRIAL',
  data: () => ({
    subscriptionStatusMapping: {
      CREATED: 'En attente de soumission',
      INITIE: 'En attente de soumission',
      ENTERPRISE_INITIATED: 'En attente de soumission',
      EN_COURS: 'En cours',
      REJETE: 'Rejeté',
      APPROUVE: 'Validé',
      POSTE: 'Validé',
      PAIEMENT_REJETE: 'Validé',
      PAIEMENT_APPROUVE: 'Validé',
      TRIAL: 'Validé',
      ECHU: 'Suspendu',
    },
  }),
};

export const PaymentStatusAliasMixin = {
  data: () => ({
    paymentStatusMapping: {
      POSTE: 'En cours',
      PAIEMENT_REJETE: 'Rejeté',
      PAIEMENT_APPROUVE: 'Validé',
      TRIAL: 'Grâce',
      ECHU: 'Validé',
      TRIAL_PAYMENT_PENDING: 'Per. Grâ. Soumis',
    },
  }),
};


