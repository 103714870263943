<template>
  <ul style="list-style: none; padding: 0">
    <li class="detail-list-item">
      Point focal :
      <b v-for="user in subscription?.details?.users" style="float: right" :key="user.id"
        >- {{ user?.firstName }} {{ user?.lastName }} <br
      /></b>
    </li>
    <li class="detail-list-item">
      E-mail : <b style="float: right">{{ subscription?.details?.email }}</b>
    </li>
    <li class="detail-list-item">
      Téléphone 1 : <b style="float: right">{{ subscription?.details?.mobile }}</b>
    </li>
    <li class="detail-list-item">
      Téléphone 2 : <b style="float: right">{{ subscription?.details?.phone }}</b>
    </li>
    <li class="detail-list-item">
      Adresse : <b style="float: right">{{ subscription?.details?.address }}</b>
    </li>
    <li class="detail-list-item">
      Site web : <b style="float: right">{{ subscription?.details?.webSite }}</b>
    </li>
    <li class="detail-list-item">
      Pays : <b style="float: right">{{ subscription?.details?.country?.name }}</b>
    </li>
    <li class="detail-list-item">
      Profil :
      <b style="float: right">
        {{ tarifications?.filter((e) => e.rang === subscription?.details?.rangSouscriptionEnCours)[0]?.adherant }}
      </b>
    </li>
    <li class="detail-list-item">
      Forme juridique : <b style="float: right">{{ subscription?.details?.legalForm?.libelle }}</b>
    </li>
    <li class="detail-list-item">
      Numéro d'identification fiscal : <b style="float: right">{{ subscription?.details?.taxIdNumber }}</b>
    </li>
    <li class="detail-list-item">
      Registre de commerce : <b style="float: right">{{ subscription?.details?.tradeRegister }}</b>
    </li>
    <li class="detail-list-item">
      Date de création :
      <b style="float: right">{{ getFormattedDate(subscription?.details?.companyCreatedAt) }}</b>
    </li>
    <li class="detail-list-item">
      Domaine(s) métier(s) :
      <b>
        <div v-for="item in subscription?.details?.domainesMetiers" :key="item.id">- {{ item.libelle }}</div>
      </b>
    </li>
    <li
      v-show="subscription?.statut === 'REJETE' || subscription?.statut == 'PAIEMENT_REJETE'"
      class="detail-list-item"
    >
      Motif de rejet :
      <b style="color: red; float: right">{{ subscription?.motifRejet }}</b>
    </li>
  </ul>
</template>

<script>
import EnumFormatMixin from '@/mixins/enum-format';
import DateFormatMixin from '@/mixins/date-format';
import http from '@/services/http.service';

export default {
  mixins: [EnumFormatMixin, DateFormatMixin],
  props: ['subscription'],
  data() {
    return {
      tarifications: [],
    };
  },
  async mounted() {
    await this.getTarifications();
  },
  methods: {
    async getTarifications() {
      await http.get('/tarifs?limit=100?sort=montant_xof,asc', this.config).then((res) => {
        this.tarifications = res.data;
        this.tarifications.sort((a, b) => b.montant_xof - a.montant_xof);
      });
    },
    getDate(dateAsArray) {
      if (!dateAsArray) return 'N/A';
      // console.log('1.', dateAsArray.join('/'));
      // console.log('2.', dateAsArray.reverse().join('/'));
      return dateAsArray.join('/');
    },
  },
};
</script>

<style>
.detail-list-item {
  padding: 10px 5px;
  border-bottom: 1px solid #e7e7e7;
  font-size: 1rem;
  display: inline-block;
  width: 100%;
}
.detail-list-item:hover {
  background-color: #eeeeee;
}
</style>
